import { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTokenAnalyzer } from 'hooks/Tokens/useTokenAnalyzer';
import { LOGIN } from 'store/actions';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    // const { isLoggedIn } = useSelector((state) => state.auth);
    const { token, refresh, userId } = useTokenAnalyzer();
    const dispatch = useDispatch();
    if (token && refresh) {
        dispatch({ type: LOGIN, payload: { token, refresh } });
    }

    return <AuthContext.Provider value={{ token, userId }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
