import React, { useState } from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem'; // Updated import
import { makeStyles, withStyles } from '@mui/styles';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import iwwfLogo from '../../../assets/images/iwwf-logo-footer.png';
import { useNavigate } from 'react-router';
// ==============================|| SIDEBAR DRAWER ||============================== //

const SidebarItem = withStyles({
    root: {
        borderRadius: 3,
        marginBottom: 5,
        '&:hover': {
            backgroundColor: '#f1f1f1',
            color: '#007bff',
            transition: 'all 0.5s'
        }
    }
})(ListItem);

const Sidebar = (props) => {
    const { items, depthStep, depth, expanded } = props;
    const navigate = useNavigate();

    console.log('loc', window.location.href);

    const handleNavigate = (item) => {
        // DONT DO THAT!!! :(
        if (item.external) {
            if (window.location.href.startsWith('http://localhost:3000')) window.location.href = `http://localhost:60254${item.url}`;
            else if (window.location.href.startsWith('https://i2e.iwwfsc.qubiteq.com'))
                window.location.href = `https://iwwfsc.qubiteq.com${item.url}`;
            else window.location.href = `https://ems.iwwf.sport${item.url}`;
        } else navigate(item.url);
    };

    return (
        <div style={{ color: '#ffffff' }}>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    marginBottom: 0
                }}
            >
                <div style={{ padding: '25px 30px 40px 7px' }}>
                    <img className="logo" src={iwwfLogo} alt="Iwwf_Logo" style={{ height: '70px' }} />
                </div>
            </Box>
            <Divider style={{ borderColor: '#ffffff1a', marginTop: 20, marginBottom: 10 }} />
            <List>
                {items.map((sidebarItem, index) => (
                    <React.Fragment key={`${sidebarItem.name}${index}`}>
                        {sidebarItem === 'divider' ? (
                            <Divider style={{ margin: '6px 0', background: 'red' }} />
                        ) : (
                            <SidebarItem button onClick={() => handleNavigate(sidebarItem)}>
                                <div style={{ paddingLeft: depth * depthStep }} className="qub-sidebar-item-content">
                                    <Typography
                                        variant="body1"
                                        style={{
                                            fontWeight: '400px',
                                            lineHeight: '1.4',
                                            fontSize: '1.3em',
                                            padding: '0px'
                                        }}
                                    >
                                        {sidebarItem.label}
                                    </Typography>
                                </div>
                            </SidebarItem>
                        )}
                    </React.Fragment>
                ))}
            </List>
            <Divider style={{ borderColor: '#ffffff1a', marginTop: 20, marginBottom: 10 }} />
        </div>
    );
};

export default Sidebar;

// import PropTypes from 'prop-types';

// // material-ui
// import { useTheme } from '@mui/material/styles';
// import { Box, Drawer, useMediaQuery } from '@mui/material';

// // third-party
// import PerfectScrollbar from 'react-perfect-scrollbar';
// import { BrowserView, MobileView } from 'react-device-detect';

// // project imports
// import MenuList from './MenuList';
// import LogoSection from '../LogoSection';
// /* import MenuCard from './MenuCard'; */
// import { drawerWidth } from 'store/constant';
// import CustomNavItem from 'ui-component/CustomNavItem';

// const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
//     const theme = useTheme();
//     const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

//     const drawer = (
//         <>
//             <Box sx={{ display: { xs: 'block', md: 'none' } }}>
//                 <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
//                     <LogoSection />
//                 </Box>
//             </Box>
//             <BrowserView
//                 style={{
//                     background: theme.palette.background.paper,
//                     borderStyle: 'solid',
//                     borderWidth: theme.palette.mode === 'light' ? 2 : 0,
//                     borderColor: theme.palette.divider
//                 }}
//             >
//                 <PerfectScrollbar
//                     component="div"
//                     style={{
//                         height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 83px)',
//                         paddingLeft: '16px',
//                         paddingRight: '16px',
//                         tabindex: -1
//                     }}
//                 >
//                     <MenuList />
//                     <CustomNavItem file="Help.pdf" />
//                 </PerfectScrollbar>
//             </BrowserView>
//             <MobileView>
//                 <Box sx={{ px: 2 }}>
//                     <MenuList />
//                     <CustomNavItem file="Help.pdf" />
//                 </Box>
//             </MobileView>
//         </>
//     );

//     const container = window !== undefined ? () => window.document.body : undefined;

//     return (
//         <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
//             <Drawer
//                 container={container}
//                 variant={matchUpMd ? 'persistent' : 'temporary'}
//                 anchor="left"
//                 open={drawerOpen}
//                 onClose={drawerToggle}
//                 sx={{
//                     '& .MuiDrawer-paper': {
//                         width: drawerWidth,
//                         background: theme.palette.common.white,
//                         color: theme.palette.text.primary,
//                         borderRight: 'none',
//                         [theme.breakpoints.up('md')]: {
//                             top: '83px'
//                         }
//                     }
//                 }}
//                 ModalProps={{ keepMounted: true }}
//                 color="inherit"
//             >
//                 {drawer}
//             </Drawer>
//         </Box>
//     );
// };

// Sidebar.propTypes = {
//     drawerOpen: PropTypes.bool,
//     drawerToggle: PropTypes.func,
//     window: PropTypes.object
// };
