import axios from 'axios';
import { store } from 'store';
import { LOGOUT, REFRESH_TOKEN } from 'store/actions';
import moment from 'moment';
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import { refreshToken } from 'apis/controllers/auth';

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL
});

axiosInstance.interceptors.request.use((config) => {
    if (config.data) {
        Object.keys(config.data).forEach(async (key) => {
            // Find a valid date object and format it
            if (config.data[key] instanceof Date && !Number.isNaN(config.data[key])) {
                config.data[key] = moment(config.data[key]).format('YYYY-MM-DDTHH:mm:ss.SSS');
            }
        });
    }
    const state = store.getState();
    if (!config.headers.Authorization) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${state.auth.token}`
        };
    }
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        console.log('error', error);
        const originalRequest = error.config;
        if (error?.message === 'Network Error') {
            const errorObject = {
                response: {
                    message: error.message,
                    status: 503
                }
            };
            return Promise.reject(errorObject);
        }
        if (error?.response?.status === 453 && !originalRequest._retry) {
            const { dispatch } = store;
            dispatch({ type: LOGOUT });
        }
        if (error?.response?.status === 403 && !originalRequest._retry) {
            const { dispatch } = store;
            dispatch({ type: LOGOUT });
            window.location.href = window.CONFIG.LoginUrl;
        }
        if (error?.response?.status === 401 && !originalRequest._retry) {
            const state = store.getState();
            const { dispatch } = store;
            originalRequest._retry = true;
            try {
                if (state.auth.refresh) {
                    const refreshResponse = await refreshToken({
                        RefreshToken: state.auth.refresh
                    });
                    dispatch({ type: REFRESH_TOKEN, payload: refreshResponse });
                    originalRequest.headers.Authorization = `Bearer ${refreshResponse?.token}`;
                    return axiosInstance(originalRequest);
                }
                dispatch({ type: LOGOUT });
                window.location.href = window.CONFIG.LoginUrl;
            } catch (ex) {
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
