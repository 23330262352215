import { useEffect } from 'react';
import { useMe } from '../../hooks/useMe';
import { Box, CircularProgress } from '@mui/material';
import { QueryStatus } from 'enums';
import { lazyLoadTranslations } from 'i18n';
import { useSettings } from 'hooks/useSettings';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

const SplashScreen = ({ children }) => {
    const { status } = useMe();
    const { projectFullTitle, projectIcon } = useSettings();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (projectFullTitle) {
            document.title = projectFullTitle;
            document.querySelector('meta[name="title"]').setAttribute('content', projectFullTitle);
        }
        if (projectIcon) {
            const favicon = document.getElementById('favicon');
            favicon.href = projectIcon;
            favicon.setAttribute('type', 'image/x-icon');
        }
    }, [projectFullTitle, projectIcon]);

    useEffect(() => {
        lazyLoadTranslations();
    }, []);

    useEffect(() => {
        if (searchParams.has('access')) {
            searchParams.delete('access');
            searchParams.delete('refresh');
            console.dir(searchParams.toString());
            setSearchParams(searchParams);
        }
    }, []);

    if (status === QueryStatus.loading) {
        return (
            <Box sx={{ display: 'flex', height: '100vh', width: '100wh', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    return children;
};

SplashScreen.propTypes = {
    children: PropTypes.node
};

export default SplashScreen;
