import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@mescius/wijmo.react.grid';
import { Box, Button, Divider, Typography, Stack, Skeleton, Tooltip, Grid, useMediaQuery } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { intentionToEnter, competitions } from 'apis';
import { useQuery } from 'react-query';
import { useState, Suspense, useRef, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@mui/material/styles';
import './explorer.css';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import { format } from 'date-fns';
import { Error, Warning, CheckCircle, Remove } from '@mui/icons-material';

const IntentionToEnter = () => {
    const { competitionId } = useParams();
    const navigate = useNavigate();
    const [i2eData, setI2eData] = useState([]);
    const [totalAthletes, setTotalAthletes] = useState(0);
    const [totalOfficials, setTotalOfficials] = useState(0);
    const gridRef = useRef(null);
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    const intentionToEnterQuery = useQuery(['intentiontoenter', competitionId], async () => {
        setI2eData([]);
        const ite = await intentionToEnter.getIntentionToEnter({ competitionId });
        const competition = await competitions.getCompetition({ competitionId });
        return { ite, competition };
    });

    useEffect(() => {
        if (intentionToEnterQuery.status === 'success') {
            setI2eData(intentionToEnterQuery.data?.ite?.federationForms);
        }
    }, [intentionToEnterQuery.status]);

    const formatDate = (date) => {
        try {
            return new Date(date).toLocaleDateString('en-GB');
        } catch (error) {
            return '-';
        }
    };

    const handleAddIntention = () => {
        navigate({
            pathname: `/intentiontoenter/manage/${competitionId}`,
            search: intentionToEnterQuery?.data?.ite?.federationId
                ? createSearchParams({ federationId: intentionToEnterQuery?.data?.ite?.federationId }).toString()
                : null
        });
    };

    const handleBack = () => {
        navigate('/titledEvents');
    };

    const handleCalculateSumms = (flex, e) => {
        setTotalAthletes(flex?.collectionView?.getAggregate(1, 'numberOfAthletes'));
        setTotalOfficials(flex?.collectionView?.getAggregate(1, 'numberOfOfficials'));
    };

    const gridInitialized = (flex) => {
        gridRef.current = flex;
        flex.selectionMode = 'None';
        flex.headersVisibility = 1;
        flex.autoRowHeights = true;
        flex.keyActionEnter = 'None';

        flex.updatedView.addHandler(handleCalculateSumms);
    };

    const flagCellTemplate = useCallback(
        (ctx) => {
            return (
                <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '3vw', py: 3 }}>
                    <Suspense fallback={<OutlinedFlagIcon />}>
                        <img
                            // style={{ paddingTop: 1, paddingBottom: 1 }}
                            src={`${process.env.PUBLIC_URL}/assets/images/Flags/${ctx.item.federationAbbr}.png`}
                            alt=""
                        />
                    </Suspense>
                    <div>{ctx.item.federationAbbr}</div>
                </Stack>
            );
        },
        [i2eData]
    );

    const dateCellTemplate = useCallback(
        (ctxDate) => {
            let date;
            try {
                date = format(new Date(ctxDate), 'dd/MM/yyyy');
            } catch (e) {
                date = null;
            }
            return <div style={{ textAlign: 'center' }}>{date}</div>;
        },
        [i2eData]
    );

    const censorDataCellTemplate = useCallback(
        (data, censor) => {
            if (censor) return '***';
            return data;
        },
        [i2eData]
    );

    const buttonCellTemplate = useCallback(
        (ctx) => {
            if (ctx?.item?.canEdit)
                return (
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ background: '#5a638d', my: 1 }}
                        onClick={() =>
                            navigate({
                                pathname: `/intentionToEnter/manage/${competitionId}`,
                                search: createSearchParams({ federationId: ctx.item?.federationId }).toString()
                            })
                        }
                    >
                        Manage
                    </Button>
                );
            return null;
        },
        [i2eData]
    );

    const paymentStatusCellTemplate = useCallback(
        (ctx) => {
            switch (ctx?.item?.paymentStatus) {
                case 1:
                    return (
                        <Stack direction="row">
                            <Tooltip title="Not Paid">
                                <CheckCircle sx={{ color: 'red' }} />
                            </Tooltip>
                            <Typography>Not Paid</Typography>
                        </Stack>
                    );
                case 2:
                    return (
                        <Stack direction="row">
                            <Tooltip title="Partially Paid">
                                <Warning sx={{ color: 'orange' }} />
                            </Tooltip>
                            <Typography>Partially Paid</Typography>
                        </Stack>
                    );
                case 3:
                    return (
                        <Stack direction="row">
                            <Tooltip title="Paid">
                                <Error sx={{ color: 'green' }} />
                            </Tooltip>
                            <Typography>Paid</Typography>
                        </Stack>
                    );
                default:
                    return (
                        <Tooltip title="Not available">
                            <Remove sx={{ color: 'gray' }} />
                        </Tooltip>
                    );
            }
        },
        [i2eData]
    );

    return {
        loading: (
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <Skeleton height={150} />
                <Skeleton height={250} />
            </Box>
        ),
        success: (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pt: 4 }}>
                <Grid
                    container
                    rowSpacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    sx={{ width: '100%', background: 'white', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)' }}
                >
                    <Grid item xs={12} md={1}>
                        <Suspense fallback={<OutlinedFlagIcon />}>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/Flags/${intentionToEnterQuery?.data?.competition?.country?.abbreviation}.png`}
                                alt=""
                                style={{ width: '5vw' }}
                            />
                        </Suspense>
                    </Grid>
                    <Grid item container justifyContent="flex-start" xs={12} md={6}>
                        <Grid item xs={12}>
                            <Typography fontFamily="Inter" fontSize={20} fontWeight={600}>
                                {intentionToEnterQuery?.data?.competition?.name ?? '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontFamily="Inter" fontSize={16} fontWeight={450}>
                                {formatDate(intentionToEnterQuery?.data?.competition?.startDate)} -{' '}
                                {formatDate(intentionToEnterQuery?.data?.competition?.endDate)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontFamily="Inter" fontSize={16} fontWeight={450}>
                                {intentionToEnterQuery?.data?.competition?.site?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={5}>
                        <Grid item container justifyContent={mdUp ? 'flex-end' : 'flex-start'} xs={12}>
                            <Typography fontFamily="Inter" fontSize={18} fontWeight={600}>
                                {intentionToEnterQuery?.data?.competition?.discipline?.description ?? '-'}
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent={mdUp ? 'flex-end' : 'flex-start'} xs={12}>
                            <Typography fontFamily="Inter" fontSize={16} fontWeight={450}>
                                {intentionToEnterQuery?.data?.competition?.code ?? '-'}
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent={mdUp ? 'flex-end' : 'flex-start'} xs={12}>
                            <Typography fontFamily="Inter" fontSize={16} fontWeight={450}>
                                {intentionToEnterQuery?.data?.competition?.competitionType?.displayName ?? '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Stack
                    sx={{
                        display: 'flex',
                        marginTop: '30px',
                        width: '100%',
                        background: 'white',
                        boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <Box
                        sx={{
                            m: 2.3
                        }}
                    >
                        <Button variant="contained" onClick={handleBack} sx={{ background: '#5a638d' }}>
                            Back
                        </Button>
                    </Box>
                    <Typography fontFamily="Inter" fontSize={22} fontWeight={500} style={{ color: 'black', margin: '10px' }}>
                        Intention To Enter Details
                    </Typography>
                    <Box sx={{ display: 'flex', flexGrow: 1, py: 4, px: 2 }}>
                        <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                            <FlexGrid className="te-grid" style={{ width: '100%' }} itemsSource={i2eData} initialized={gridInitialized}>
                                <FlexGridColumn width="*" binding="id" visible={false} />
                                <FlexGridColumn width="*" binding="federationAbbr" header="Country">
                                    <FlexGridCellTemplate cellType="Cell" template={flagCellTemplate} />
                                </FlexGridColumn>
                                <FlexGridColumn width="3*" binding="federation" header="Federation" wordWrap />
                                <FlexGridColumn width="*" binding="createdAt" header="Created">
                                    <FlexGridCellTemplate cellType="Cell" template={(ctx) => dateCellTemplate(ctx.item?.createdAt)} />
                                </FlexGridColumn>
                                <FlexGridColumn width="*" binding="updatedAt" header="Last Update">
                                    <FlexGridCellTemplate cellType="Cell" template={(ctx) => dateCellTemplate(ctx.item?.updatedAt)} />
                                </FlexGridColumn>
                                <FlexGridColumn width="*" binding="numberOfAthletes" header="# Athletes">
                                    <FlexGridCellTemplate
                                        cellType="Cell"
                                        template={(ctx) => censorDataCellTemplate(ctx.item?.numberOfAthletes, !ctx.item?.canEdit)}
                                    />
                                </FlexGridColumn>
                                <FlexGridColumn width="*" binding="numberOfOfficials" header="# Contacts">
                                    <FlexGridCellTemplate
                                        cellType="Cell"
                                        template={(ctx) => censorDataCellTemplate(ctx.item?.numberOfOfficials, !ctx.item?.canEdit)}
                                    />
                                </FlexGridColumn>
                                <FlexGridColumn width="*" binding="paymentStatus" header="Payment Status" allowSorting={false}>
                                    <FlexGridCellTemplate
                                        cellType="Cell"
                                        template={paymentStatusCellTemplate}
                                        // template={(ctx) => censorDataCellTemplate(ctx.item?.numberOfAthletes, !ctx.item?.canEdit)}
                                    />
                                </FlexGridColumn>
                                <FlexGridColumn width="*" binding="Action" header=" ">
                                    <FlexGridCellTemplate cellType="Cell" template={buttonCellTemplate} />
                                </FlexGridColumn>
                            </FlexGrid>
                            <Divider flexItem />
                            <Grid container rowSpacing={2} justifyContent="space-between" p={1} sx={{ width: '100%' }}>
                                <Grid item container justifyContent="flex-start" xs={12} md={6}>
                                    <Tooltip
                                        disabled={!intentionToEnterQuery?.data?.CompHeaders?.canAdd}
                                        title="You can not add new Intention to Enter. You can only edit your saved Intention to Enter"
                                    >
                                        <span>
                                            <Button
                                                variant="contained"
                                                sx={{ background: '#5a638d' }}
                                                disabled={!intentionToEnterQuery?.data?.ite?.canAdd}
                                                onClick={handleAddIntention}
                                            >
                                                Add+
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item container justifyContent={mdUp ? 'flex-end' : 'flex-start'} xs={12} md={6}>
                                    <Stack direction="column" spacing={1} sx={{ background: '#f8f8f8', p: 2 }}>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                            <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
                                                Total Athletes:
                                            </Typography>
                                            <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
                                                {totalAthletes}
                                            </Typography>
                                        </Stack>
                                        <Divider />
                                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                            <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
                                                Total Officials:
                                            </Typography>
                                            <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
                                                {totalOfficials}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        ),
        error: <div>Error</div>
    }[intentionToEnterQuery.status];
};

export default IntentionToEnter;
