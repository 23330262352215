import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import Loadable from 'qubCommon/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const HomeView = Loadable(lazy(() => import('views/dashboard/Home')));

// const TermsOfUse = Loadable(lazy(() => import('views/TermsOfUse')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = () => {
    return [
        {
            path: '/',
            element: <MinimalLayout />,
            children: [
                {
                    path: 'home',
                    element: <HomeView />
                }
            ]
        },
        {
            path: '*',
            element: <Navigate to="/home" />
        }
    ];
};
export default AuthenticationRoutes;
