export const FileTypes = {
    png: 'image/png',
    pdf: 'application/pdf',
    jpg: 'image/jpeg'
};
export const QueryStatus = {
    idle: 'idle',
    loading: 'loading',
    success: 'success',
    error: 'error'
};
