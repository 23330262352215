/* eslint-disable import/no-cycle */
import axiosInstance from '../axiosInstance';

export const authApi = {
    post: async (path, params, cancelToken) =>
        axiosInstance
            .post(`/auth${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err.error ? err : err?.response;
            }),
    get: async (path, params, cancelToken) =>
        axiosInstance
            .get(
                `/auth${path}`,
                { params },
                {
                    cancelToken: cancelToken?.token
                }
            )
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response;
            })
};

export const ValidateVerificationPin = async ({ userIdentifier, value }) => authApi.post('/verifyEmail', { userIdentifier, value });

export const getUserHasRoles = async (params) => authApi.post('/userhasroles', params);

export const updateUserHasRoles = async (params) => authApi.post('/updateuserhasroles', params);

export const getUserPermissionTree = async (params) => authApi.post('/userPermissionTree', params);

export const updateUserPermissionTree = async (params) => authApi.post('/updateuserPermissionTree', params);

export const getRolePermissionTree = async (params) => authApi.post('/RolePermissionTree', params);

export const updateRolePermissionTree = async (params) => authApi.post('/updateRolePermissionTree', params);

export const getMe = async () => authApi.get('/getUserDetails');

export const logout = async (params) => authApi.post('/logout', params);

export const refreshToken = async (params) => authApi.post('/refresh', params);

export const validateToken = async ({ token, type }) => authApi.post('/validatetoken', { token, type });

export const changeUsernameRequest = async (params) => authApi.post('/changeusernamerequest', params);

export const verifyUsernameRequest = async (params) => authApi.post('/verifyusernamerequest', params);

export const changePasswordRequest = async (params) => authApi.post('/changepassword', params);
