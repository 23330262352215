import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { apiEntities } from 'apis';

export const lazyLoadTranslations = async () => {
    const data = await apiEntities.unauthorizedGetData({ dataKey: 'Translations-Get' });

    data?.map((lang) => {
        i18n.addResourceBundle(lang.ln, lang.namespace, lang.datavalues);
        return null;
    });
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        partialBundledLanguages: true,
        transSupportBasicHtmlNodes: true,
        fallbackLng: 'en',
        defaultNS: 'common',
        interpolation: {
            escapeValue: false
        },
        resources: {}
    });

export default i18n;
