import { memo, createContext, useEffect } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { apiEntities } from 'apis';
import { useLogout } from 'hooks/useLogOut';
import { useSnackbar } from 'notistack';
import { defineAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { useNavigate } from 'react-router';
import { useAuth } from './authContext';

const UserContext = createContext();

const minutesToMSeconds = ({ minutes }) => minutes * 60 * 1000;

const UserProvider = memo(({ children }) => {
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { logout } = useLogout();
    const { userId } = useAuth();

    const meQuery = useQuery(
        'me',
        async () => {
            try {
                const res = await apiEntities.getData({ dataKey: 'UserData-Get', parameters: { userId } });
                const formatedPermissions = res?.data?.Permissions.map((dbPermission) => {
                    const [subject, action] = dbPermission?.DataKey.split('.');
                    return {
                        action,
                        subject
                    };
                });
                const abilities = defineAbility((can) => {
                    formatedPermissions.forEach((element) => {
                        can(element.action, element.subject);
                    });
                });

                return { ...res, permissions: abilities };
            } catch (error) {
                console.info('res', error);
                if (error?.status === 400) {
                    enqueueSnackbar('Your connection has expired, please login again', {
                        variant: 'error'
                    });
                    logout();
                }
                if (error?.status === 503) {
                    navigate('site-is-temporarily-unavailable');
                }
                throw error;
            }
        },
        {
            retry: 1,
            refetchInterval: minutesToMSeconds({ minutes: 120 })
            ///  enabled: isLoggedIn
        }
    );

    useEffect(() => {
        if (isLoggedIn) {
            meQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);
    return <UserContext.Provider value={meQuery}>{children}</UserContext.Provider>;
});

UserProvider.propTypes = {
    children: propTypes.node
};

export { UserProvider, UserContext };
export const Can = createContextualCan(UserContext.Consumer);
