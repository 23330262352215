import axiosInstance from '../axiosInstance';

export const intentionToEnterApi = {
    post: async (path, params, cancelToken) =>
        axiosInstance
            .post(`/intentiontoenter${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err.error ? err : err?.response;
            }),
    get: async (path, params, cancelToken) =>
        axiosInstance
            .get(
                `/intentiontoenter${path}`,
                { params },
                {
                    cancelToken: cancelToken?.token
                }
            )
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response;
            })
};

// export const getIntentionToEnter = async (params) => intentionToEnterApi.get(`/getintentiontoenter/${params}`);
export const getIntentionToEnter = async (params) => intentionToEnterApi.get('/getintentiontoenter', params);

export const getIntentionToEnterForm = async (params) => intentionToEnterApi.post('/getintentiontoenterform', params);

export const saveIntentionToEnter = async (params) => intentionToEnterApi.post('/saveintentiontoenter', params);
