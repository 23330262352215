import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { decodeToken } from 'utils';
import { auth } from 'apis';
import { useQuery } from 'react-query';

export const useTokenValidator = ({ token, type }) => {
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await auth.validateToken({ token, type });
                return res;
            } catch (e) {
                console.log(e);
                return false;
            }
        };
        if (token && type) fetchData();
    }, [token, type]);

    return { isValid };
};

export const useTokenAnalyzer = () => {
    const [searchParams] = useSearchParams();

    const token = searchParams.get('access');
    const refresh = searchParams.get('refresh');

    searchParams.delete('access');
    searchParams.delete('refresh');
    const user = token ? decodeToken(token) : null;

    return { token, refresh, userId: user?.nameid };
};
