import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'qubCommon/Loadable';

// permission
import { withPermission } from 'hoc';

// react-router
import { Navigate } from 'react-router-dom';
import MinimalLayout from 'layout/MinimalLayout';
import CommonIndex from 'common/CommonIndex';
import IntentionToEnter from 'views/application/intentionToEnter';
// dashboard routing

const ImpersonationView = withPermission(Loadable(lazy(() => import('views/admin/Impersonation'))), {
    action: 'View',
    subject: 'FederationAdminsPanel'
});

const TitledEvents = Loadable(lazy(() => import('views/application/titledEvents')));
const IntentionToEnterForm = Loadable(lazy(() => import('views/application/IntentionToEnterForm')));

const CompetitionsView = withPermission(Loadable(lazy(() => import('views/application/competitions'))), {
    action: 'View',
    subject: 'CompetitionsPanel'
});

const PageNotFound = Loadable(lazy(() => import('views/PageNotFound')));
const ServerError = Loadable(lazy(() => import('views/ServerError')));

const MainRoutes = () => [
    {
        path: '',
        element: <MainLayout />,
        children: [
            {
                path: 'impersonation',
                element: <ImpersonationView />
            },
            {
                path: 'competitions',
                element: <CompetitionsView />
            },
            {
                path: 'titledEvents',
                element: <TitledEvents />
            },
            {
                path: 'intentionToEnter/:competitionId',
                element: <IntentionToEnter />
            },
            {
                path: 'intentiontoenter/manage/:competitionId',
                element: <IntentionToEnterForm />
            },
            {
                path: '',
                element: <Navigate to="competitions" />
            },
            {
                path: 'site-is-temporarily-unavailable',
                element: <ServerError />
            },
            {
                path: '*',
                element: <PageNotFound />
            }
        ]
    },

    {
        path: 'statistics',
        element: <MainLayout />,
        children: [{ path: '', element: <CommonIndex /> }]
    }
    // ,
    // {
    //     path: '',
    //     element: <MainLayout />,
    //     children: []
    // }
];

export default MainRoutes;
