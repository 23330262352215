import { memo } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';

// store
import { useSelector } from 'react-redux';
import { useMenu } from 'hooks/useMenu';
// ==============================|| ROUTING RENDER ||============================== //

const Routes = memo(() => {
    const { isLoggedIn } = useSelector((state) => state.auth);
    const routes = isLoggedIn ? MainRoutes() : AuthenticationRoutes();
    // useMenu();

    return useRoutes(routes, config.basename);
});

export default Routes;
