import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthFooter from 'ui-component/cards/AuthFooter';

// material-ui
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import {
    Box,
    Container,
    ToggleButtonGroup,
    Divider,
    Button,
    TextField,
    FormControl,
    Menu,
    MenuItem,
    InputLabel,
    Select,
    Typography,
    Stack
} from '@mui/material';
import { Person, Language } from '@mui/icons-material';
import ToggleButton from '@mui/material/ToggleButton';

import { styled, useTheme } from '@mui/material/styles';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import { useMe } from 'hooks/useMe';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '0px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            paddingTop: '8px',
            paddingBottom: '8px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
            paddingTop: '8px',
            paddingBottom: '8px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
    const me = useMe();
    const navigate = useNavigate();
    // Example data for the sidebar items.
    const items = [
        { label: 'Titled Events', name: 'titledevents', url: '/titledevents' },
        { label: 'About', name: 'about', url: '/about' },
        { label: 'Back to Competitions', name: 'competitions', url: '/Organizer/MyCompetitions', external: true }
    ];

    // State for sidebar.
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // Function to hide sidebar.
    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    // Function to expand panel.
    const [expanded, setExpanded] = useState(false);

    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleNavigate = () => {
        if (window.location.href.startsWith('http://localhost:3000')) window.location.href = 'http://localhost:60254';
        else if (window.location.href.startsWith('https://i2e.iwwfsc.qubiteq.com')) window.location.href = 'https://iwwfsc.qubiteq.com';
        else window.location.href = 'https://ems.iwwf.sport';
    };

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            {/* Sidebar */}
            <Box
                style={{
                    position: 'relative',
                    background: '#121B3F',
                    left: sidebarVisible ? 0 : -250,
                    width: 250,
                    transition: 'left 0.3s ease'
                }}
            >
                <Sidebar items={items} depthStep={10} depth={0} expanded />
            </Box>

            {/* Main Content */}
            <Container
                maxWidth={false}
                style={{
                    flex: 1,
                    padding: '20px',
                    background: '#F6F6F6',
                    marginLeft: sidebarVisible ? 0 : -250,
                    transition: 'margin-left 0.3s ease',
                    position: 'relative',
                    overflowX: 'auto',
                    maxHeight: '100vh'
                }}
            >
                <>
                    <div className="container-fluid" style={{ background: 'white', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)' }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <ToggleButtonGroup size="small">
                                <ToggleButton
                                    value="dummy" // Got a console error for not setting a value.
                                    selected={sidebarVisible}
                                    onClick={toggleSidebar}
                                    style={{ background: '#5969ff', margin: '15px', marginLeft: '15px' }}
                                >
                                    <FormatAlignLeftIcon style={{ color: 'white' }} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ mr: 1 }}>
                                <Button startIcon={<Language />} sx={{ color: '#f0346e', textTransform: 'none' }} onClick={handleNavigate}>
                                    Go to EMS Public
                                </Button>
                                <Divider orientation="vertical" flexItem />
                                <Button
                                    startIcon={<Person />}
                                    sx={{ color: '#f0346e', textTransform: 'none' }}
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                >
                                    {me?.whoami?.data?.UserName}
                                </Button>
                            </Stack>
                        </Stack>
                    </div>
                    <Outlet />
                </>
            </Container>
            <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
                <Stack direction="column" spacing={1} sx={{ p: 2 }}>
                    <Typography sx={{ color: '#f0346e', fontSize: 20 }}>Logged in as</Typography>
                    <Typography variant="body2">{`${me?.whoami?.data?.Lastname} ${me?.whoami?.data?.Firstname}`}</Typography>
                    <Typography variant="body2">{me?.whoami?.data?.ConfederationAbbr}</Typography>
                    <Divider />
                    <Typography sx={{ color: '#f0346e', fontSize: 20 }}>Roles</Typography>
                    {me?.whoami?.data?.Roles?.length > 0 &&
                        me?.whoami?.data?.Roles?.map((role) => (
                            <Typography key={role?.RoleId} variant="body2">
                                {role?.Description}
                            </Typography>
                        ))}
                </Stack>
            </Menu>
        </div>
    );
};

export default MainLayout;

//   <Box sx={{ display: 'flex' }}>
//             <CssBaseline />
//             {/* header */}
//             <AppBar
//                 enableColorOnDark
//                 position="fixed"
//                 color="inherit"
//                 elevation={0}
//                 sx={{
//                     bgcolor: theme.palette.background.paper,
//                     transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
//                     boxShadow: 3
//                 }}
//             >
//                 <Toolbar>
//                     <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
//                 </Toolbar>
//             </AppBar>

//             {/* drawer */}
//             <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

//             {/* main content */}
//             <Main
//                 sx={{ background: theme.palette.background.default, mt: 10, mr: 0 }}
//                 style={{ boxSizing: 'content-box' }}
//                 theme={theme}
//                 open={leftDrawerOpened}
//             >
//                 {/* <Breadcrumbs separator={IconChevronRight} navigation={menuItems} icon title rightAlign /> */}
//                 <Outlet />
//             </Main>
//         </Box>
