import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { QueryClient, QueryClientProvider } from 'react-query';

import { SettingsProvider } from 'context/settingsContext';

// permissions
import { PermissionProvider } from 'context/permissionContext';

import { AuthProvider } from 'context/authContext';

import { UserProvider } from 'context/userContext';

import SplashScreen from './views/splashScreen';

import { ModalProvider } from 'qubCommon/context/modalContext';

import { BringValueProvider } from 'context/BringValueContext/bringValueContext';

import { LoaderProvider } from 'context/loaderContext';

import { MobileConnectionProvider } from 'context/SignalR/mobileActionsConnection';

import { setLicenseKey } from '@mescius/wijmo';

import { LoadingDialog } from './hoc';

import 'font-awesome/css/font-awesome.min.css';

import './assets/css/qubStyles.css';

const queryClient = new QueryClient({
    defaultOptions: {
        retry: 1,
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    try {
        const data = window.localStorage.getItem('CONFIG');
        window.CONFIG = JSON.parse(data);
        console.log('wijmo key try', window.CONFIG.WIJMO_LICENSE_KEY);
        setLicenseKey(window.CONFIG.WIJMO_LICENSE_KEY);
    } catch (e) {
        console.log(e);
        const data = window.localStorage.getItem('CONFIG');
        window.CONFIG = JSON.parse(data);
        console.log('wijmo key catch', window.CONFIG.WIJMO_LICENSE_KEY);
        setLicenseKey(window.CONFIG.WIJMO_LICENSE_KEY);
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <QueryClientProvider client={queryClient}>
                    <CssBaseline />
                    <LoaderProvider>
                        <LoadingDialog />
                        <ModalProvider>
                            <NavigationScroll>
                                <AuthProvider>
                                    <UserProvider>
                                        <BringValueProvider>
                                            <PermissionProvider>
                                                {/* <SettingsProvider> */}
                                                <SplashScreen>
                                                    <Routes />
                                                </SplashScreen>
                                                {/* </SettingsProvider> */}
                                            </PermissionProvider>
                                        </BringValueProvider>
                                    </UserProvider>
                                </AuthProvider>
                            </NavigationScroll>
                        </ModalProvider>
                    </LoaderProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
